<template>
  <v-app>
    <custom-app-bar
      :sidebar="sidebar"
      :items="items"
      :item-icon="itemIcon"
      @sidebar-event="handleSideBar"
    />
    <v-navigation-drawer
      v-model="sidebar"
      app
      dark
      color="primary"
      style="width: 100%"
      src="/img/bg-a.jpg"
    >
      <v-layout column justify-center justify-space-evenly fill-height>
        <div class="">
          <v-list-item>
            <v-list-item-title class="d-flex justify-center align-center">
              <v-img
                src="/img/logo-vertical-white.png"
                alt="alt"
                class=""
                max-width="150"
              />
            </v-list-item-title>
          </v-list-item>
        </div>
        <v-list dense nav expand class="mt-10 pt-10 mx-16">
          <v-list-item
            v-for="item in items"
            :key="item.title"
            @click="handleNavigation(item)"
          >
            <v-list-item-avatar>
              <v-icon color="white"> mdi-waves </v-icon>
            </v-list-item-avatar>
            <v-list-item-content class="py-3">
              <h3>{{ item.title }}</h3>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-layout>
      <template v-slot:append>
        <div class="pb-16 mb-16 d-flex align-center justify-center">
          <v-btn
            @click="handleSideBar(false)"
            icon
            outlined
            elevation="0"
            color="secondary"
          >
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <v-main class="pt-0">
      <v-row id="heading-1" align="end" class="f-h bg-home" no-gutters>
        <v-col cols="12" md="6" class="">
          <div class="pt-md-15 mt-16 pt-16">
            <div
              class="bg-hero"
              :style="
                $vuetify.breakpoint.lgAndUp
                  ? 'max-width: 85%'
                  : $vuetify.breakpoint.md
                  ? 'max-width: 90%'
                  : 'max-width: 80%'
              "
            >
              <h3
                class="py-4 custom_blue--text title-font text-lg-h3 text-md-h4 pl-10 pl-md-15 pr-4"
                data-aos="fade-down"
                data-aos-duration="1500"
              >
                {{ $t("lblSuEmbarcacion") }}
              </h3>
            </div>
            <div class="pl-10 pl-md-15 pt-md-10 mt-16 mt-md-2 pt-16">
              <v-btn
                color="custom_blue"
                :x-large="$vuetify.breakpoint.mdAndUp"
                :large="$vuetify.breakpoint.xs"
                dark
                data-aos="fade-up"
                data-aos-duration="1500"
                @click="goToContacto()"
              >
                {{ $t("btnQuieroServicios") }}
              </v-btn>
            </div>
          </div>
        </v-col>
        <v-col cols="12" align="center" class="d-flex flex-column">
          <v-img
            :src="'/img/ancla.png'"
            max-height="30"
            contain
            data-aos="fade-up"
            data-aos-delay="600"
            data-aos-anchor-placement="center-bottom"
            data-aos-duration="2000"
            data-aos-easing="ease-in-out"
          ></v-img>
          <small
            class="white--text pt-3"
            data-aos="fade-up"
            data-aos-delay="700"
            data-aos-duration="2000"
            data-aos-anchor-placement="center-bottom"
          >
            {{ $t("lblDesliza") }}
          </small>
        </v-col>
      </v-row>
      <v-row id="heading-2" class="custom_blue" no-gutters>
        <v-col cols="12" align="center" class="pt-15 px-5 px-md-1">
          <h1
            class="pt-15 secondary--text title-font text-h5 text-lg-h4 text-md-h4"
            data-aos="fade-down"
            data-aos-delay="300"
            data-aos-duration="1500"
          >
            {{ $t("lblServiciosNauticos") }}
          </h1>
          <h5
            class="white--text body-font pt-8 pb-16 text-h6 text-md-h6"
            data-aos="fade-up"
            data-aos-delay="400"
            data-aos-duration="1500"
          >
            {{ $t("lblOfrecemos") }}
          </h5>
        </v-col>
        <v-col cols="12" md="10" lg="8" class="offset-md-1 offset-lg-2 pb-15">
          <v-row>
            <v-col
              cols="12"
              md="4"
              align="center"
              class="py-10 px-15 px-md-0"
              v-for="service in services"
              :key="service.name"
            >
              <individual-service :item="service" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <about-us />
      <mision-vision />
      <our-values />
      <opinion-banner />
      <available-certifications />
      <project-status />
      <preview-proyects />
      <contacto-banner />
    </v-main>
    <custom-footer />
    <div class="social_container">
      <v-speed-dial
        v-model="floatingButton"
        bottom
        right
        direction="top"
        transition="slide-y-reverse-transition"
        :open-on-hover="$vuetify.breakpoint.mdAndUp"
      >
        <template v-slot:activator>
          <v-btn v-model="floatingButton" color="blue darken-2" dark fab>
            <v-icon v-if="floatingButton"> mdi-close </v-icon>
            <v-img
              v-else
              class="trasparent"
              contain
              max-height="35"
              max-width="35"
              :src="'/img/iconos/chat.png'"
            ></v-img>
          </v-btn>
        </template>
        <v-btn
          fab
          small
          color="transparent"
          class=""
          href="https://www.instagram.com/gymserviciosnauticos/"
          target="_blank"
        >
          <v-img
            class="trasparent"
            contain
            max-height="47"
            max-width="47"
            :src="'/img/iconos/instagram.png'"
          ></v-img>
        </v-btn>
        <v-btn
          fab
          small
          color="transparent"
          class=""
          href="https://www.facebook.com/gymservinauticos"
          target="_blank"
        >
          <v-img
            class="trasparent"
            contain
            max-height="47"
            max-width="47"
            :src="'/img/iconos/facebook.png'"
          ></v-img>
        </v-btn>
        <v-btn
          fab
          small
          color="transparent"
          class=""
          href="https://wa.me/59662352?text=Me%20gustaría%20contratar%20sus%20servicios."
          target="_blank"
        >
          <v-img
            class="trasparent"
            contain
            max-height="47"
            max-width="47"
            :src="'/img/iconos/whatsapp.png'"
          ></v-img>
        </v-btn>
      </v-speed-dial>
    </div>
  </v-app>
</template>
<script>
import CustomFooter from "@/components/Home/CustomFooter";
import CustomAppBar from "@/components/Home/CustomAppBar";
import IndividualService from "@/components/Home/IndividualService";
import AboutUs from "@/components/Home/sections/AboutUs";
import ProjectStatus from "@/components/Home/sections/projectStatus";
import PreviewProyects from "@/components/Home/sections/PreviewProyects";
import vuetify from "@/plugins/vuetify";
import AvailableCertifications from "@/components/Home/sections/AvailableCertifications";
import MisionVision from "@/components/Home/sections/MisionVision";
import OurValues from "@/components/Home/sections/OurValues.vue";
import OpinionBanner from "@/components/Home/sections/OpinionBanner.vue";
import ContactoBanner from "@/components/Home/sections/ContactoBanner.vue";
export default {
  name: "HomeView",
  components: {
    ContactoBanner,
    OpinionBanner,
    OurValues,
    PreviewProyects,
    ProjectStatus,
    AboutUs,
    IndividualService,
    CustomAppBar,
    CustomFooter,
    AvailableCertifications,
    MisionVision,
  },
  data: function () {
    return {
      floatingButton: true,
      isMobile: false,
      sidebar: false,
      itemIcon: {
        tarjet: "#heading-1",
        outlined: true,
        toPage: false,
      },
      items: [
        {
          title: this.$t("titleQueHacemos"),
          tarjet: "#heading-2",
          text: true,
          outlined: false,
          toPage: false,
        },
        {
          title: this.$t("titleQuienesSomos"),
          tarjet: "#heading-3",
          text: true,
          outlined: false,
          toPage: false,
        },
        {
          title: this.$t("titleSuReparacion"),
          tarjet: "#heading-4",
          text: true,
          outlined: false,
          toPage: false,
        },
        {
          title: this.$t("titleProyectos"),
          tarjet: "#heading-5",
          text: true,
          outlined: false,
          toPage: false,
        },
        {
          title: this.$t("titleOpinionesHead"),
          tarjet: "#heading-opinion",
          text: true,
          outlined: false,
          toPage: false,
        },
        {
          title: this.$t("titleContacto"),
          nameRoute: "Contacto",
          text: true,
          outlined: false,
          toPage: true,
        },
      ],
      services: [
        {
          name: this.$t("nameReparacionMotores"),
          url: "/img/iconos/reparacion-motores.png",
          width: {
            md: "50%",
            lg: "50%",
          },
        },
        {
          name: this.$t("nameReparacionGeneradores"),
          url: "/img/iconos/reparacion-generadores.png",
          width: {
            md: "60%",
            lg: "50%",
          },
        },
        {
          name: this.$t("nameReparacionSistemasElectricos"),
          url: "/img/iconos/reparacion-sistemas-electricos.png",
          width: {
            md: "80%",
            lg: "60%",
          },
        },
        {
          name: this.$t("nameReparacionSistemasHidraulicos"),
          url: "/img/iconos/reparacion-sistemas-hidraulicos.png",
          width: {
            md: "80%",
            lg: "60%",
          },
        },
        {
          name: this.$t("nameReparacionSistemasRefrigeracion"),
          url: "/img/iconos/reparacion-sistemas-aire-acondicionado.png",
          width: {
            md: "90%",
            lg: "70%",
          },
        },
        {
          name: this.$t("nameInstalacionElectronicos"),
          url: "/img/iconos/instalacion-equipos-electronicos-accesorios.png",
          width: {
            md: "90%",
            lg: "80%",
          },
        },
      ],
    };
  },
  methods: {
    vuetify() {
      return vuetify;
    },
    handleSideBar(sidebarValue) {
      this.sidebar = sidebarValue;
    },
    closeNavigation() {
      this.handleSideBar(false);
    },
    handleNavigation(item) {
      if (item.toPage) {
        this.$router.push({ name: item.nameRoute });
      } else {
        this.$vuetify.goTo(item.tarjet);
      }
      if (this.$vuetify.breakpoint.xs) {
        this.handleSideBar();
      }
    },
    goToContacto() {
      this.$router.push({ name: "Contacto" });
    },
    onResize() {
      if (window.innerWidth >= 600) {
        this.sidebar = false;
      }
    },
  },
  mounted() {
    this.onResize();
    this.$store.dispatch("projects/getPublicProjects");
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>
<style scoped src="@/assets/css/home.css"></style>
<style scoped>
.bg-home {
  background-image: url("~@/assets/images/nautica.jpg");
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right center;
}

@media (max-width: 600px) {
  .bg-home {
    background-image: url("~@/assets/images/nautica-mobile-2.jpg");
    background-position: right bottom;
  }
}

.v-speed-dial {
  position: absolute;
}
.v-btn--floating {
  position: relative;
}

.bg-hero {
  background: rgba(204, 178, 154, 0.69);
  border-radius: 10px;
}
</style>
