import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import NuestroTrabajo from "@/views/NuestroTrabajo";
import { CheckSession } from "../auth/";
import ProyectosView from "@/views/ProyectosView.vue";
import OpinionesView from "@/views/OpinionesView.vue";
import ContactoView from "@/views/ContactoView.vue";

Vue.use(VueRouter);

async function requireAuth(to, from, next) {
  try {
    await CheckSession();
    next();
  } catch (error) {
    localStorage.clear();
    router.push({ name: "home" });
  }
}

async function checkSession(to, from, next) {
  try {
    await CheckSession();
    router.push({ name: "Projects" });
  } catch (error) {
    next();
  }
}

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/nuestro-trabajo",
    component: ProyectosView,
    children: [
      {
        path: "",
        name: "NuestroTrabajo",
        component: NuestroTrabajo,
      },
      {
        path: "proyecto/:id",
        name: "DetailedView",
        component: () => import("@/components/proyects/DetailedView"),
      },
    ],
  },
  {
    path: "/opiniones",
    name: "Opiniones",
    component: OpinionesView,
  },
  {
    path: "/contacto",
    name: "Contacto",
    component: ContactoView,
  },
  {
    path: "/login",
    component: () => import("../views/AuthView.vue"),
    children: [
      {
        path: "",
        name: "Auth",
        component: () => import("@/components/Auth/Login"),
      },
      {
        path: "change",
        name: "ChangePassword",
        component: () => import("@/components/Auth/ChangePassword"),
        props: (route) => ({
          ...route.params,
        }),
      },
      {
        path: "recover",
        name: "Recover",
        component: () => import("@/components/Auth/RecoverAccount"),
      },
    ],
    beforeEnter: checkSession,
  },
  {
    path: "/projects",
    // name: "Admin",
    beforeEnter: requireAuth,
    component: () => import("../views/AdminView.vue"),
    children: [
      {
        path: "",
        name: "Projects",
        component: () => import("@/components/Admin/Projects"),
      },
    ],
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    // always scroll to top
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 });
      }, 500);
    });
  },
});

export default router;
