<template>
  <v-row class="px-16">
    <v-col cols="12" class="pa-0 ma-0">
      <v-row v-if="formSend" no-gutters class="py-15 pt-lg-8 pb-lg-16 px-lg-16">
        <v-col cols="12" align="center" class="py-10">
          <v-img width="120" contain :src="'/img/sail.gif'"> </v-img>
        </v-col>
        <v-col cols="12" align="center" class="px-5 px-md-0">
          <h1
            class="primary--text title-font text-lg-h3 text-md-h3"
            data-aos="fade-down"
            data-aos-delay="200"
            data-aos-duration="1500"
          >
            {{ $t("titleGraciasC") }}
          </h1>
        </v-col>
        <v-col
          cols="12"
          md="8"
          class="offset-2 py-5 px-md-12 px-lg-16 support--text"
        >
          <div class="">
            <h3 class="body-font font-weight-medium">
              {{ $t("GraciasCMessage") }}
            </h3>
            <h3 class="pt-4 body-font font-weight-medium">
              {{ $t("GraciasCMessageDos") }}
            </h3>
          </div>
          <div class="d-flex justify-space-between pt-15">
            <v-btn
              color="support"
              outlined
              elevation="0"
              @click="goto('NuestroTrabajo')"
            >
              {{ $t("btnTodoProyecto") }}
            </v-btn>
            <v-btn
              color="support"
              outlined
              elevation="0"
              @click="goto('Opiniones')"
            >
              {{ $t("btnVerOpiniones") }}
            </v-btn>
            <v-btn color="support" outlined elevation="0" @click="goto('home')">
              {{ $t("btnIrHome") }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row v-else no-gutters class="pb-15 px-md-1 px-lg-16">
        <v-col cols="12" align="center" class="px-md-0 py-lg-16">
          <h1
            class="primary--text title-font text-lg-h3 text-md-h3"
            data-aos="fade-down"
            data-aos-delay="200"
            data-aos-duration="1500"
          >
            {{ $t("titleContactanos") }}
          </h1>
          <h4
            class="font-weight-medium support--text body-font pt-5 pb-5 text-lg-h6 px-md-16"
            data-aos="fade-down"
            data-aos-delay="300"
            data-aos-duration="1500"
          >
            {{ $t("subtitleContactanos") }}
          </h4>
        </v-col>
        <v-col cols="12" md="7" lg="7" class="px-md-10 px-lg-16">
          <v-form ref="form" lazy-validation>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  filled
                  color="primary"
                  :placeholder="$t('lblNombre')"
                  dense
                  v-model="item.name"
                  :rules="noEmptyRules"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  filled
                  color="primary"
                  :placeholder="$t('lblTelefono')"
                  dense
                  v-model="item.phoneNumber"
                  :rules="noEmptyRules"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  filled
                  color="primary"
                  :placeholder="$t('lblCorreo')"
                  dense
                  v-model="item.email"
                  :rules="emailRules"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  filled
                  color="primary"
                  :placeholder="$t('lblMotivo')"
                  dense
                  v-model="item.reason"
                  :rules="noEmptyRules"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  filled
                  dense
                  rows="3"
                  no-resize
                  color="primary"
                  :placeholder="$t('lblComentario')"
                  v-model="item.comment"
                  :rules="noEmptyRules"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="12" class="d-flex justify-end">
                <v-btn
                  large
                  :block="$vuetify.breakpoint.xs"
                  color="primary"
                  elevation="0"
                  @click="sendMessageContact"
                  :loading="loading"
                >
                  {{ $t("btnEnviar") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-col
          cols="12"
          md="5"
          lg="4"
          class="my-10 mt-md-0 mb-md-16 font-weight-medium text-body-1 body-font"
        >
          <div class="mb-3">
            <small class="font-weight-bold text-body-1 body-font primary--text">
              {{ $t("lblTelefonoContacto") }}
            </small>
          </div>
          <div class="mb-3">
            <small
              class="font-weight-medium text-body-1 body-font support--text"
            >
              +(502) 5966-2352 , +(502) 7930-5198
            </small>
          </div>
          <div class="mb-3">
            <small class="font-weight-bold text-body-1 body-font primary--text">
              {{ $t("lblEscribeEmail") }}
            </small>
          </div>
          <div class="mb-3">
            <small
              class="font-weight-medium text-body-1 body-font support--text"
            >
              mruiz@gymserviciosnauticos.com
            </small>
          </div>
          <div class="mb-3">
            <small class="font-weight-bold text-body-1 body-font primary--text">
              {{ $t("lblVisitanosDir") }}
            </small>
          </div>
          <div>
            <small
              class="font-weight-medium text-body-1 body-font support--text"
            >
              {{ $t("lblDireccion") }}
            </small>
          </div>
          <div class="my-3">
            <small class="font-weight-bold text-body-1 body-font primary--text">
              {{ $t("lblSigueRedes") }}
            </small>
          </div>
          <div class="d-flex justify-start">
            <div></div>
            <v-img
              :src="urlFacebook"
              class="cursor"
              @click="goToFb"
              max-width="25"
            ></v-img>
            <v-img
              max-width="25"
              @click="goToIG"
              :src="urlInstagram"
              class="cursor mx-4"
            ></v-img>
            <v-img
              @click="goToTKT"
              :src="urlTiktok"
              class="cursor"
              max-width="25"
            >
            </v-img>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { contactanos } from "@/api";
import { messages } from "@/utils";
export default {
  name: "ContactUs",
  data: function () {
    return {
      loading: false,
      item: {
        name: "",
        email: "",
        reason: "",
        comment: "",
        phoneNumber: "",
      },
      emailRules: [
        (value) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) ||
          this.$t("lblValidEmail"),
      ],
      noEmptyRules: [(value) => !!value || this.$t("lblCompletaDato")],
      formSend: false,
      urlFacebook: "/img/iconos/fb.png",
      urlTiktok: "/img/iconos/tk.png",
      urlInstagram: "/img/iconos/ig.png",
    };
  },
  methods: {
    goto(routeName) {
      this.$router.push({ name: `${routeName}` });
    },
    goToFb() {
      window.open("https://www.facebook.com/gymservinauticos", "blank_");
    },
    goToTKT() {
      window.open("https://www.tiktok.com/@gymserviciosnauticos", "blank_");
    },
    goToIG() {
      window.open("https://www.instagram.com/gymserviciosnauticos", "blank_");
    },
    recaptcha() {
      return new Promise((resolve) => {
        this.$recaptchaLoaded().then(() => {
          this.$recaptcha("contactanos").then((token) => {
            resolve(token);
          });
        });
      });
    },
    async sendMessageContact() {
      if (!this.$refs.form.validate()) return;
      this.loading = true;
      this.formSend = false;
      const token = await this.recaptcha();
      const data = {
        ...this.item,
        token,
      };
      const { error } = await contactanos(data);
      this.loading = false;
      if (error) {
        this.formSend = false;
        messages.error(this.$t("msgContactError"));
      } else {
        this.formSend = true;
        messages.success(this.$t("msgContactSuccess"));
        this.clearForm();
      }
    },
    clearForm() {
      this.item = {
        name: "",
        email: "",
        reason: "",
        comment: "",
        phoneNumber: "",
      };
    },
  },
};
</script>
<style scoped src="@/assets/css/home.css"></style>
<style scoped>
.cover-contact-us {
  min-height: 90vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-image: url("~@/assets/images/contactanos-bg-2.png") !important;
}
</style>
