<template>
  <v-row class="pb-10">
    <v-col cols="12" align="center" class="pt-15">
      <h1
        class="pt-15 primary--text title-font text-h4 px-md-0 px-5"
        data-aos="fade-down"
        data-aos-delay="2800"
        data-aos-duration="1500"
        data-aos-anchor-placement="bottom-center"
      >
        {{ $t("lblCapacitados") }}
      </h1>
    </v-col>
    <v-col cols="12">
      <v-row align="center" justify="center" class="pt-16 px-10">
        <v-col cols="12" md="6" align="center">
          <v-img
            contain
            height="172"
            width="182"
            :src="'/img/NMEA.png'"
            data-aos="fade-up"
            data-aos-delay="3000"
            data-aos-duration="1500"
            data-aos-anchor-placement="bottom-center"
          >
          </v-img>
          <v-card
            flat
            color="custom_grey"
            max-width="400"
            class="mt-10 pa-5 text-left"
            data-aos="fade-up"
            data-aos-delay="3000"
            data-aos-duration="1500"
            data-aos-anchor-placement="bottom-center"
          >
            <v-card-text>
              <p class="pb-0 mb-0 body-font">
                {{ $t("lblCertificado1") }}
              </p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" align="center">
          <v-img
            contain
            height="158"
            width="419"
            :src="'/img/ABYC.png'"
            data-aos="fade-up"
            data-aos-delay="3000"
            data-aos-duration="1500"
            data-aos-anchor-placement="bottom-center"
          >
          </v-img>
          <v-card
            flat
            color="custom_grey"
            max-width="400"
            class="mt-10 pa-5 text-left"
            data-aos="fade-up"
            data-aos-delay="3000"
            data-aos-duration="1500"
            data-aos-anchor-placement="bottom-center"
          >
            <v-card-text>
              <p class="pb-0 mb-0 body-font">
                {{ $t("lblCertificado2") }}
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="10" align="center" class="offset-md-1">
      <h1
        class="pt-15 primary--text title-font text-lg-h4 text-md-h4"
        data-aos="fade-down"
        data-aos-delay="3000"
        data-aos-duration="1500"
        data-aos-anchor-placement="bottom-center"
      >
        {{ $t("titleCertificaciones") }}
      </h1>
    </v-col>
    <v-col
      cols="12"
      md="10"
      align="center"
      class="offset-md-1"
      data-aos="fade-up"
      data-aos-delay="1000"
      data-aos-duration="1500"
      data-aos-anchor-placement="bottom-center"
    >
      <v-carousel
        cycle
        :height="$vuetify.breakpoint.mdAndUp ? 700 : 400"
        hide-delimiter-background
        show-arrows-on-hover
      >
        <v-carousel-item v-for="(slide, i) in aleatorieSlides" :key="i">
          <v-sheet color="transparent" height="100%">
            <v-row class="fill-height" align="center" justify="center">
              <v-img
                contain
                :src="slide.src"
                :max-height="$vuetify.breakpoint.mdAndUp ? 600 : 300"
                :max-width="$vuetify.breakpoint.mdAndUp ? 700 : 250"
                class="img-shadow"
              >
              </v-img>
            </v-row>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "AvailableCertifications",
  data() {
    return {
      colors: ["primary", "secondary"],
      slides: [
        {
          src: "/img/certificaciones/cert_det.png",
        },
        {
          src: "/img/certificaciones/cert_m_abyc.png",
        },
        {
          src: "/img/certificaciones/cert_m_nmea.png",
        },
        {
          src: "/img/certificaciones/cert_ddec.png",
        },
        {
          src: "/img/certificaciones/cert_m_nmea_mei.png",
        },
        {
          src: "/img/certificaciones/cert_deree.png",
        },
        {
          src: "/img/certificaciones/cert_s2000.png",
        },
        {
          src: "/img/certificaciones/cert_s4000.png",
        },
      ],
    };
  },
  computed: {
    aleatorieSlides() {
      return this.slides.slice().sort(() => Math.random() - 0.5);
    },
  },
};
</script>
<style scoped src="@/assets/css/home.css"></style>
<style scoped>
.v-carousel-item img {
  max-height: 100%;
  max-width: 100%;
}
.img-shadow {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
</style>
