<template>
  <v-app>
    <custom-app-bar
      :sidebar="sidebar"
      :items="items"
      :item-icon="itemIcon"
      @sidebar-event="handleSideBar"
      is-light
    />
    <v-navigation-drawer
      v-model="sidebar"
      app
      dark
      color="primary"
      style="width: 100%"
      src="/img/bg-a.jpg"
    >
      <v-layout column justify-center justify-space-evenly fill-height>
        <div class="">
          <v-list-item>
            <v-list-item-title class="d-flex justify-center align-center">
              <v-img
                src="/img/logo-vertical-white.png"
                alt="alt"
                class=""
                max-width="150"
              />
            </v-list-item-title>
          </v-list-item>
        </div>
        <v-list dense nav expand class="mt-10 pt-10 mx-16">
          <v-list-item
            v-for="item in items"
            :key="item.title"
            @click="handleNavigation(item)"
          >
            <v-list-item-avatar>
              <v-icon color="white"> mdi-waves </v-icon>
            </v-list-item-avatar>
            <v-list-item-content class="py-3">
              <h3>{{ item.title }}</h3>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-layout>
      <template v-slot:append>
        <div class="pb-5 d-flex align-center justify-center">
          <v-btn
            @click="handleSideBar(false)"
            icon
            outlined
            elevation="0"
            color="secondary"
          >
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <v-main class="mt-20">
      <v-row id="" align="end" class="f-h-c bg-home mt-20" no-gutters> </v-row>
      <contact-us />
      <div v-if="showButtonTop" class="floating_container">
        <v-btn
          color="custom_blue"
          elevation="5"
          rounded
          dark
          @click="goToTop()"
        >
          <v-icon> mdi-arrow-up </v-icon>
        </v-btn>
      </div>
    </v-main>
    <custom-footer />
  </v-app>
</template>

<script>
import CustomAppBar from "@/components/Home/CustomAppBar";
import CustomFooter from "@/components/Home/CustomFooter";
import { mapState } from "vuex";
import ContactUs from "@/components/Home/sections/ContactUs.vue";
export default {
  name: "ContactoView",
  components: {
    ContactUs,
    CustomFooter,
    CustomAppBar,
  },
  data: function () {
    return {
      isMobile: false,
      sidebar: false,
      items: [
        {
          title: this.$t("titleRegresarInicio"),
          nameRoute: "home",
          text: false,
          outlined: true,
          toPage: true,
        },
      ],
      itemIcon: {
        nameRoute: "home",
        outlined: true,
        toPage: true,
      },
      showButtonTop: false,
    };
  },
  computed: {
    ...mapState({}),
  },
  methods: {
    goToTop() {
      this.$vuetify.goTo("#app_bar");
    },
    handleSideBar(sidebarValue) {
      this.sidebar = sidebarValue;
    },
    handleNavigation(item) {
      if (item.toPage) {
        this.$router.push({ name: item.nameRoute });
      } else {
        this.$vuetify.goTo(item.tarjet);
      }
      if (this.$vuetify.breakpoint.xs) {
        this.handleSideBar();
      }
    },
    onResize() {
      if (window.innerWidth >= 600) {
        this.sidebar = false;
      }
    },
    handleTopButton() {
      if (
        document.body.scrollTop > 400 ||
        document.documentElement.scrollTop > 400
      ) {
        this.showButtonTop = true;
      } else {
        this.showButtonTop = false;
      }
    },
  },
  mounted() {
    window.onscroll = () => {
      this.handleTopButton();
    };
    this.onResize();
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>
<style scoped src="@/assets/css/home.css"></style>
<style scoped>
.max-w {
  max-width: 1200px;
}

.floating_container {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 10px;
  right: 30px;
  z-index: 100;
}

.bg-home {
  background-image: url("~@/assets/images/contactanos-bg-2.png") !important;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
}

@media (max-width: 600px) {
  .bg-home {
    background-position: center bottom;
  }
  .f-h-c {
    height: 100vh !important;
  }
}

.f-h-c {
  height: 110vh !important;
}
</style>
