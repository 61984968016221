// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/images/nautica.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/assets/images/nautica-mobile-2.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bg-home[data-v-1e1a8c50]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;background-repeat:no-repeat;background-position:100%}@media (max-width:600px){.bg-home[data-v-1e1a8c50]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-position:100% 100%}}.v-speed-dial[data-v-1e1a8c50]{position:absolute}.v-btn--floating[data-v-1e1a8c50]{position:relative}.bg-hero[data-v-1e1a8c50]{background:hsla(29,33%,70%,.69);border-radius:10px}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
