// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/images/contactanos-bg-2.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".max-w[data-v-38fa6fcd]{max-width:1200px}.floating_container[data-v-38fa6fcd]{position:fixed;width:50px;height:50px;bottom:10px;right:30px;z-index:100}.bg-home[data-v-38fa6fcd]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")!important;background-size:cover;background-repeat:no-repeat;background-position:bottom}@media (max-width:600px){.bg-home[data-v-38fa6fcd]{background-position:bottom}.f-h-c[data-v-38fa6fcd]{height:100vh!important}}.f-h-c[data-v-38fa6fcd]{height:110vh!important}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
